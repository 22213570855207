var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.carousel.pageCount > 1),expression:"carousel.pageCount > 1"}],staticClass:"VueCarousel-pagination",class:{ [`VueCarousel-pagination--${_vm.paginationPositionModifierName}`]: _vm.paginationPositionModifierName }},[_c('div',{staticClass:"VueCarousel-dot-container",style:(`margin-top: ${_vm.carousel.paginationPadding * 2}px;`),attrs:{"role":"tablist"}},_vm._l((_vm.paginationCount),function(page,index){return _c('button',{key:`${page}_${index}`,staticClass:"VueCarousel-dot",class:{ 'VueCarousel-dot--active': _vm.isCurrentDot(index) },style:(`
        margin-${_vm.paginationPropertyBasedOnPosition}: ${_vm.carousel.paginationPadding * 2}px;
        padding: ${_vm.carousel.paginationPadding}px;
        width: ${_vm.carousel.paginationSize}px;
        height: ${_vm.carousel.paginationSize}px;
        background-color: ${_vm.isCurrentDot(index) ? _vm.carousel.paginationActiveColor : _vm.carousel.paginationColor};
      `),attrs:{"aria-hidden":"false","role":"tab","title":_vm.getDotTitle(index),"value":_vm.getDotTitle(index),"aria-label":_vm.getDotTitle(index),"aria-selected":_vm.isCurrentDot(index) ? 'true' : 'false'},on:{"click":function($event){return _vm.goToPage(index)}}})}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }